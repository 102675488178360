import { StarIcon } from "@heroicons/react/20/solid";

export default function StarRating({
  rating,
  className,
  size = 5,
}: {
  rating: number;
  className?: string;
  size?: number;
}) {
  className = className ?? "";
  const roundedRating = Math.round(rating * 2) / 2;

  const heightClass = `h-${size}`;
  const widthClass = `w-${size}`;
  const halfWidthClass = `w-${size / 2}`;

  return (
    <div className={`flex items-center ${className}`}>
      {[1, 2, 3, 4, 5].map(i => {
        let starClass = "text-gray-300";

        if (roundedRating >= i) {
          starClass = "text-yellow-400";
          return (
            <StarIcon
              key={`${i}`}
              className={`${starClass} ${heightClass} ${widthClass} flex-shrink-0`}
              aria-hidden="true"
            />
          );
        } else if (i - roundedRating === 0.5) {
          return (
            <div key={`${i}`} className={`flex ${widthClass}`}>
              <div
                className={`${halfWidthClass} ${heightClass} overflow-hidden`}
              >
                <StarIcon
                  className={`text-yellow-400 ${heightClass} ${widthClass} flex-shrink-0`}
                  aria-hidden="true"
                />{" "}
              </div>
              <div
                className={`${halfWidthClass} ${heightClass} overflow-hidden transform scale-x-[-1]`}
              >
                <StarIcon
                  className={`text-gray-300 ${heightClass} ${widthClass} flex-shrink-0`}
                  aria-hidden="true"
                />{" "}
              </div>
            </div>
          );
        } else {
          return (
            <StarIcon
              key={`${i}`}
              className={`${starClass} ${heightClass} ${widthClass} flex-shrink-0`}
              aria-hidden="true"
            />
          );
        }
      })}
    </div>
  );
}
